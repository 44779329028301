 .btn-primario {
      background-color: #000;
      color: #fff;
    }

 .btn-primario:hover {
      background-color: #000;
      color: #fff;
      filter: brightness(60%);
    }

.btn-pericolo {
      background-color: #fff;
      color: #f22626;
      border: 1px solid #f22626;
    }
    .btn-pericolo:hover {
      background-color: #f22626;
      color: #fff;
      border: 1px solid #f22626;
    }